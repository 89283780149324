import '../style/layout.css'
import logo from '../assets/images/LOGO.jpg'
import React, { useEffect } from 'react';
const Planing=()=>{
    useEffect(() => {
        document.title = 'kidsandfun stage enfants planing visé hermalle';
      });
  return(
    <main style={{ padding: "1rem 0" }}>
        <h2>Déroulement de la journée type</h2>
        <p>
            <ul className='no-puce ul-margin'>
                <li><span className='title-underline'>8H30-9H :</span>Garderie.</li>
                <li><span className='title-underline'>9H-9H15 :</span>Rassemblement des enfants et prise des présences.</li>
                <li><span className='title-underline'>9H15-10H30 :</span>Début des activités.</li>
                <li><span className='title-underline'>10H30-10H45 :</span>Pause de 15 min.</li>
                <li><span className='title-underline'>10H45-12H :</span>Reprise des activités.</li>
                <li><span className='title-underline'>12H-13H :</span>Pause de midi.</li>
                <li><span className='title-underline'>13H-14H15 :</span>Reprise des activités.</li>
                <li><span className='title-underline'>14H15-14H30 :</span>Pause de 15 min.</li>
                <li><span className='title-underline'>14H30-15H45 :</span>Reprise des activités.</li>
                <li><span className='title-underline'>15H45-16H :</span>Rassemblement et stretching de fin de journée + retour au calme.</li>
                <li><span className='title-underline'>16H00-16H30 :</span>Garderie.</li>
            </ul>
        </p>
        <div className='row'>
            <div className='col-md-12'>
                <img src={logo} alt={"Kids & Fun"} className='' />
            </div>
        </div>
    </main>
    )}

export default Planing;