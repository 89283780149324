import '../style/about.css'
import { NavLink } from "react-router-dom";
import React, { useEffect } from 'react';
const About=()=>{
  useEffect(() => {
    document.title = 'kidsandfun stage enfants visé hermalle';
  });
  return(
    <main style={{ padding: "1rem 0" }}>
        <h2>Programme K&F</h2>
        <p>
        Programme de découvertes sportives à destination des 6-12 ans, la KIDS AND FUN propose aux enfants de s’initier à des disciplines sportives tout au long de l’année scolaire en période de congés scolaires.
        Outre l'aspect "sportif", le volet Formation prend une part importante de notre projet éducatif. Au delà de l'exercice physique, nous accordons de l'importance aux loisirs et à la détente. Les autres pratiques sont tout aussi essentielles : les activités de loisirs et de détente, les jeux, les repas, la vie en commun, les moments d'échange, la vie quotidienne. 

        Partant du principe que l’aspect technicotactique sera abordé en club, l’approche de nos séances est basée sur l’aspect ludique des activités. Notre priorité : apprendre en s’amusant et éveiller la curiosité des enfants vis-à-vis des sports qu’ils ont choisis.

        Sports raquettes, sports collectifs, sports individuels, sports découvertes …
        🡺 Le panel proposé permettra aux enfants de goûter aux différents axes existants dans le milieu du sport.

        </p>
        <h3>DANS QUEL CADRE ?</h3>
        <p>
            <ul>
                <li>La qualité de la formation est l'élément principal de notre philosophie. C'est pourquoi les cours sont dispensées par des moniteurs diplômés en éducation physique. </li>
                <li>Attention, les images publicitaires sont à titre indicatif, merci de lire le détail pédagogique de chaque stage.</li>
                <li>Nos groupes sont composés d’enfants ayant peu, ou jamais pratiqué la discipline choisie. L’idée étant de permettre aux pratiquants de découvrir sereinement les activités, dans un même groupe de niveau. (principalement pour le multisport)</li>
                <li>Les feedbacks et l’individualisation sont essentiels aux développements des aptitudes des enfants. Ainsi, selon les disciplines, nous travaillons avec des groupes composés de 10-15 enfants maximum par moniteurs. Chaque enfant reçoit donc un retour régulier et constructif de la part des encadrants.</li>
            </ul>
        </p>
        <h3>AVEC QUELS OBJECTIFS ?</h3>
        <p>
            <ul>
                <li>Amener les enfants à découvrir leur discipline idéale, en fonction de leurs aptitudes motrices, physiques et affectives.</li>
                <li>Favoriser le jeu et le plaisir durant les activités, nous désirons lutter contre le décrochage sportif des enfants.</li>
                <li>Aborder de manière ludique nos séances, sans pression ni esprit de compétition, nous voulons amener les enfants à poursuivre leur apprentissage dans les clubs de la région.</li>
                <li>Mettre le matériel à disposition des enfants. Nous désirons rendre la pratique sportive accessible à tous les enfants.</li>
            </ul>
        </p>
        <div className='row'>
          <div className='col-md-12'>
            <span><NavLink to="/charte"  className=''>Voir notre charte du Fair Play</NavLink></span>
          </div>
        </div>
        
      </main>
  )
}

  export default About;