import { API } from 'aws-amplify';
import config from '../aws-exports';
import Constants from '../Constants';
API.configure(config);

export const APIKEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const STAGE_SHEETID = process.env.REACT_APP_GOOGLE_SHEET_ID;

export async function GetFileInFolder(folderId) {
    return new Promise(async(resolve,error)=>{
        const requestOptions = {
            method: 'GET',
            headers: { 'Accept': '*',
                        'Content-Type': 'application/json', 
                        'Origin':Constants.FRONT_URL
            }
        };
        await fetch(Constants.API_URL+'/GetFileInFolder?folderId='+folderId, requestOptions)
        .then(response => response.json())
        .then(response => {  
            resolve(response);   
        }).catch(err => {console.log(err);return null;});
    });
}