import '../style/catalog.css'
import { useCheckPermission } from '../utils/hooks/useCheckPermission';
import { GetFileInFolder } from "../data/OGoogleDrive";
import  PictureCard  from "../components/PictureCard";
import  VideoCard  from "../components/VideoCard";
import Constants from '../Constants';
import React, { useState, useEffect } from 'react';
const Catalog=()=>{
  const [state, setState] = useState(
    // (state, newState) => ({...state, ...newState}),
    {picturesList: [],videosList: [], checkPermission: useCheckPermission("FullAdmin"),isAdmin:false}
  );
  const [loading, setLoading] = useState(false);
  const [picturesList, setPictureList] = useState([]);
  const [videosList, setVideoList] = useState([]);
  const checkPermission = useCheckPermission("FullAdmin");
  const [isAdmin, setIsAdmin] = useState(false); 

  useEffect(() => {
    document.title = 'kidsandfun stage enfants visé hermalle';
    const fetchData = async () => {
      setLoading(true);
      let res = await checkPermission.then(async(res)=>{
        setIsAdmin(res);
        
        const tempList = await GetFileInFolder(Constants.FOLDER_PICTURE_ID).then(async(data)=>{
          setPictureList(data);
          // setLoading(false);
          const tempListVideo = await GetFileInFolder(Constants.FOLDER_VIDEO_ID).then((data)=>{
            setVideoList(data);
            setLoading(false);
          });
        });

        
      });
    }
    fetchData();
  },[setPictureList, setIsAdmin, setLoading]);

  if(loading)
    return (<div className="loader-container">
    <div className="spinner"></div>
  </div>);
  return(
    <main style={{ padding: "1rem 0" }}>
        <h2>En route pour Milan</h2>
        {(videosList && videosList.length>0)?(
                            <h3 className='mt-5'>Videos</h3>                       
                    ) : (
                    ""
                    )}
            {videosList && videosList.length>0 && videosList.map((video)=>(
              <VideoCard info={{VideoId:video.id, name:video.name}}/>
            ))}
        {(picturesList && picturesList.length>0)?(
                            <h3 className='mt-5'>Photos</h3>                       
                    ) : (
                    ""
                    )}
        <row className="row">
            {picturesList && picturesList.length>0 && picturesList.map((picture)=>(
              <PictureCard info={{pictureId:picture.id, name:picture.name}}/>
            ))}
        </row>
        
      </main>
  )
}

  export default Catalog;