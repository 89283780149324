import '../style/footer.css'
import { NavLink } from "react-router-dom";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF }  from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GenCond from '../assets/documents/Conditions Général KF.pdf';
import RGPD from '../assets/documents/RGPD KF.pdf';
import Rules from '../assets/documents/reglement d\'ordre interieur KF.pdf';
export default function Footer() {
    return (
            <div className="row footer">
                <div className="col-md-4 about">
                    <h5>À propos</h5>
                    <p className="mt-4">
                        On vous propose des stages de qualité avec une équipe professionnelle
                    </p>
                    <p className="mt-2">
                        TVA: BE 0788.317.614
                    </p>
                    <p className="mt-2">
                        <a className="text-primary" href="tel:0474376456">0474376456</a>
                    </p>
                    <p className="mt-2">
                        <a className="text-primary" href="tel:0491221432">0491221432</a>
                    </p>
                    <p className="mt-2">
                        <a className="" href="mailto:kandfasbl@outlook.com">kandfasbl@outlook.com</a>
                    </p>
                    <p className="mt-2">
                        <a href="https://www.facebook.com/profile.php?id=100086448894423" target="_blank">
                        <FontAwesomeIcon icon={faFacebookF} />
                         </a>
                    </p>
                </div>
                <div className="col-md-4">
                    <h5>Plan du site</h5>
                    <ul>
                        <li><FontAwesomeIcon icon={faAngleRight} /><NavLink to="/home"  className=''>Accueil</NavLink></li>
                        <li><FontAwesomeIcon icon={faAngleRight} /><NavLink to="/Traineeship"  className=''>Stages</NavLink></li>
                        <li><FontAwesomeIcon icon={faAngleRight} /><NavLink to="/about"  className=''>À propos</NavLink></li>
                        <li><FontAwesomeIcon icon={faAngleRight} /><NavLink to="/contact"  className=''>Contact</NavLink></li>
                    </ul>
                </div>
                <div className="col-md-4">
                    <h5>Documents annexes</h5>
                    <ul>
                        <li><FontAwesomeIcon icon={faAngleRight} /><NavLink to="/charte"  className=''>Notre charte</NavLink></li>
                        <li><FontAwesomeIcon icon={faAngleRight} /><a href = {RGPD} target = "_blank">RGPD</a></li>
                        <li><FontAwesomeIcon icon={faAngleRight} /><a href = {GenCond} target = "_blank">Conditions générales</a></li>
                        <li><FontAwesomeIcon icon={faAngleRight} /><NavLink to="/planing"  className=''>Déroulement d'une journée type</NavLink></li>
                        <li><FontAwesomeIcon icon={faAngleRight} /><NavLink to="/programme"  className=''>Programme</NavLink></li>
                        <li><FontAwesomeIcon icon={faAngleRight} /><a href = {Rules} target = "_blank">Règlement d'ordre intérieur</a></li>
                    </ul>
                </div>
            </div> 
    );
  }