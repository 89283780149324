 import ContactForm from '../components/ContactForm'
 import React, { useEffect } from 'react';
 function Contact() {
  useEffect(() => {
    document.title = 'kidsandfun stage visé-hermalle';
  });
    return (
      <main style={{ padding: "1rem 0" }}>
        <h2>Contact</h2>
        <ContactForm/>
      </main>
    );
  }
  export default Contact;