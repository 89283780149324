import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useCheckPermission } from '../utils/hooks/useCheckPermission';
import { NavLink } from "react-router-dom";
import '../style/keypoint.css'

function Keypoint({keypointHome}){
    const checkPermission = useCheckPermission("FullAdmin");
    const [kp, setKP] = useState(keypointHome); 
    const [isAdmin, setIsAdmin] = useState(false); 
    useEffect(() => {  
        async function fetchData(){
            // const checkPermission = await useCheckPermission("FullAdmin");
            let res = await checkPermission;
            setIsAdmin(res)
          }
          fetchData();
        
        // let res = await checkPermission;
        // setIsAdmin(res) 
      }, []);
    
    
    return (
        <NavLink to={kp.link}  className='link-button'>{kp.title}</NavLink>
    );
};
export default Keypoint