import '../style/banner.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import { useCheckPermission } from '../utils/hooks/useCheckPermission';
import { getKeypointHomeList } from "../data/OCMS";
import  Keypoint  from "./Keypoint";
import home1 from '../assets/images/home/home1.jpg'
import home2 from '../assets/images/home/home2.jpg'
import home3 from '../assets/images/home/home3.jpg'
import logo from '../assets/images/LOGO.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from "swiper";
import { faFacebookF }  from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink, Link } from "react-router-dom";
import 'swiper/css/autoplay';

// Import Swiper styles
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
function Banner(){
    const [keypointHomeList, setKeypointHomeList] = useState([]);
    const checkPermission = useCheckPermission("FullAdmin");
    const [isAdmin, setIsAdmin] = useState(false); 
    const title = 'Bienvenue sur le site de '
    const desc = `Programme de découvertes sportives à destination des 6-12 ans, la KIDS AND FUN propose aux enfants de s’initier à des disciplines sportives tout au long de l’année scolaire en période de congés scolaires.

    Outre l'aspect "sportif", le volet Education prend une part importante de notre projet éducatif. Au delà de l'exercice physique, nous accordons de l'importance aaux loisirs et à la détente Durant la construction de la personnalité des jeunes, les vacances sportives doivent constituer des moments privilégiés pour leur épanouissement. Les autres pratiques sont tout aussi essentielles : les activités de loisirs et de détente, les jeux, les repas, la vie en commun, les moments d'échange, la vie quotidienne ; tout cela forme un tout indissociable et nous prenons en compte cette réalité !
     `
     useEffect(() => {    
        async function fetchData(){
          let res = await checkPermission;
          setIsAdmin(res)
          const tempList = await getKeypointHomeList();
          setKeypointHomeList(tempList);
        }
        fetchData();
      }, [keypointHomeList, isAdmin]);

    return (
        <div className='inner-margin'>
            <div className='row justify-content-center mb-5'>
                <div className='col-md-12 col-sm-12'>
                <h1>{title}<span className="bold purple">Kids & Fun ASBL</span></h1>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-md-6 col-sm-12'>
                    <Swiper navigation={true} pagination={true} modules={[Pagination,Navigation,Autoplay]}
                    autoplay={{ delay: 5000 }}
                    slidesPerView={1}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    >
                        <SwiperSlide><img src={logo} alt={title} className='main-logo' /></SwiperSlide>
                        {/* <SwiperSlide><img src={home2} alt={title} className='main-logo' /></SwiperSlide>
                        <SwiperSlide><img src={home3} alt={title} className='main-logo' /></SwiperSlide> */}
                    </Swiper>
                </div>    
                <div className='col-md-6 col-sm-12 pt-5'>
                    {keypointHomeList && keypointHomeList.length>0 && keypointHomeList.map((keypointHome)=>(
                        
                        <div className='row main-link'>
                            <div className='col-md-12'>
                                <Keypoint keypointHome={keypointHome}/>
                                {/* <NavLink to="/traineeship"  className='link-button'>Découvir les stages</NavLink> */}
                            </div>         
                        </div>
                    ))}
                    {/* <p>{desc}<span><NavLink to="/about"  className=''>Voir plus</NavLink></span></p> */}
                    <div className='row main-link'>
                            <div className='col-md-12'>
                            <a href="https://www.facebook.com/profile.php?id=100086448894423" target="_blank" className='link-button-fb' >
                                <FontAwesomeIcon style={{position:'relative',top:'5px'}} icon={faFacebookF} />&nbsp;&nbsp;Nous suivre
                            </a>
                            </div>         
                        </div>
                </div>      
            </div>
            
        </div>
)
}
export default Banner;