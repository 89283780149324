import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useCheckPermission } from '../utils/hooks/useCheckPermission';
import TraineeshipForm from './TraineeshipForm';
import moment from "moment";
import '../style/card.css'

function VideoCard({info}){
      return (<div className="row" style={{height:"400px"}}><div className='col-md-12'>
        <iframe src={"https://drive.google.com/uc?export=view&id="+info.VideoId} width="100%" height="100%" allow="autoplay"></iframe>
      </div></div>);
}
export default VideoCard;