import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useCheckPermission } from '../utils/hooks/useCheckPermission';
import TraineeshipForm from './TraineeshipForm';
import moment from "moment";
import '../style/card.css'

function PictureCard({info}){
      return (<div className='col-lg-3 col-md-4 col-sm-12'>
                <img src={"https://drive.google.com/uc?export=view&id="+info.pictureId} alt={info.name} width="100%" height="100%"></img>
          </div>);
}
export default PictureCard;