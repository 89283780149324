import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useCheckPermission } from '../utils/hooks/useCheckPermission';
import TraineeshipForm from '../components/TraineeshipForm';
import moment from "moment";
import '../style/card.css'

function Card({traineeship}){
    const checkPermission = useCheckPermission("FullAdmin");
    const [stage, setStage] = useState(traineeship); 
    const [isAdmin, setIsAdmin] = useState(false); 
    useEffect(() => {  
        async function fetchData(){
            // const checkPermission = await useCheckPermission("FullAdmin");
            let res = await checkPermission;
            setIsAdmin(res)
          }
          fetchData();
        
        // let res = await checkPermission;
        // setIsAdmin(res) 
      }, [setIsAdmin]);
    //on n'affiche pas les non visible ou date dépassée
    let specific_date = new Date(moment(stage.from,'DD/MM/YYYY').format('YYYY-MM-DD'));
    let current_date = new Date();
    current_date.setDate(current_date.getDate() - 7)
    if( stage.Active.toLowerCase()==="false" || current_date.getTime() > specific_date.getTime()){
        return;
    }
    return (
        
                <div className="col-md-6 col-lg-4 col-sm-12 card">
                    <div className='row justify-content-center position-relative'>
                        <div>
                            <Link style={{ display: "block", margin: "1rem 0" }} to={`/traineeship/${stage.rowIndex}`} key={stage.traineeshipID}>
                                <img src={stage.img} alt={stage.title} /> 
                                {(stage.full==="TRUE" || parseInt(stage.RemainPlace)<=0) && <div className="card-overlay bg-white"></div>}  
                            </Link>
                        </div>
                        {(stage.full==="TRUE" || parseInt(stage.RemainPlace)<=0) && <div className="head">
                                                <span className="badge bg-danger rounded-pill">Complet</span>
                                            </div>}  
                    </div>
                    <div className='card-body'>
                        <div className='row head-card'>
                            <div className='col-md-11'>
                            <h5>{stage.title}</h5>
                            </div>   
                            {isAdmin===true ? (
                                <div className='col-md-1'>
                                    <TraineeshipForm stageState={setStage} traineeship={traineeship} editMode={true}/>
                                </div>
                            ) : (
                            ""
                            )}      
                        </div>
                        <div className='row text-muted'>
                            <div className='col-md-12'>
                            {stage.description}  
                            </div>         
                        </div>
                        <div className='row text-muted'>
                            <div className='col-md-12'>
                            {stage.place.toLowerCase().indexOf("hermalle")>-1?(<a className="text-primary" href="https://www.google.com/maps/place/Hall+Omnisport/@50.7142561,5.6807928,15z/data=!4m2!3m1!1s0x0:0x880fcc59f7891ce5?sa=X&ved=2ahUKEwjeu_Tzq6X6AhXG26QKHX1EDoAQ_BJ6BAg0EAU" target="_blank">{stage.place}</a>) : (
                                                <a className="text-primary" href="https://www.google.com/maps/place/Salle+du+Refuge+d'Aaz/@50.7085677,5.6186703,15z/data=!4m5!3m4!1s0x0:0xe0d65f4195c55021!8m2!3d50.7085654!4d5.618715https://www.google.com/maps/place/Salle+du+Refuge+d'Aaz/@50.7085677,5.6186703,15z/data=!4m5!3m4!1s0x0:0xe0d65f4195c55021!8m2!3d50.7085654!4d5.618715" >{stage.place}</a>
                                            )} 
                            </div>         
                        </div>
                        <Link style={{ display: "block", margin: "1rem 0" }} to={`/traineeship/${stage.rowIndex}`} key={stage.traineeshipID+'__'}>
                            <span className="text-primary read-more" >En savoir plus <FontAwesomeIcon icon={faAngleRight} /></span>
                        </Link>
                    </div>
                </div>
        
    );
};
export default Card