import '../style/charte.css'
import '../style/layout.css'
import charte1 from '../assets/images/other/charte1.png'
import charte2 from '../assets/images/other/charte2.png'
import logo from '../assets/images/LOGO.jpg'
import React, { useEffect } from 'react';
const Charte=()=>{
    useEffect(() => {
        document.title = 'kidsandfun stage enfants visé hermalle';
      });
  return(
    <main style={{ padding: "1rem 0" }} className="text-center inner-margin">
        <div className='row'>
            <div className='col-md-6 d-sm-none d-md-block header-icon'><img src={charte1} alt={"fair play"} className='' /></div>
            <div className='col-md-6 col-sm-12 header-icon'><img src={charte2} alt={"fair play"} className='' /></div>
        </div>
        <h2>Notre Charte du <span className='txt-important'>FAIR-PLAY</span> </h2>
        <h2><span className='title-organisation'>KIDS & FUN:</span></h2>
        <h2><span className='title-organisation'>Je m’engage à</span></h2>
        <p className='margin-top'>
            <ul className='bigger-size no-puce ul-margin'>
                <li>Faire de chaque activité sportive, peu importe l’enjeu, une sorte de fête ou un moment privilégié.</li>
                <li>Respecter mes adversaires et mes coéquipiers ainsi que moi-même.</li>
                <li>Eviter la méchanceté et les agressions dans mes actes, mes paroles et mes écrits.</li>
                <li>Ne pas user d’artifices ni de tricheries pour gagner.</li>
                <li>Rester digne dans la victoire comme dans la défaite</li>
                <li>Aider chacun par ma présence, mon expérience et ma compréhension.</li>
                <li>Porter secours à tout qui en a besoin.</li>
                <li>Accepter les décisions des arbitres et des moniteurs sachant que, comme moi, ils ont droit à l’erreur mais font tout pour ne pas en commettre.</li>
                
            </ul>
        </p>
        <p>
            <span className='txt-important'>Par ces engagements, je considère que je suis un vrai sportif, digne de</span>
        </p>
        <div className='row'>
            <div className='col-md-12'>
                <img src={logo} alt={"Kids & Fun"} className='' />
            </div>
        </div>
      </main>
  )
}

  export default Charte;