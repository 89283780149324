import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { NavLink } from "react-router-dom";
import emailjs from '@emailjs/browser';
import emailkey from "../tools/emailkey";
import gtag from 'ga-gtag';
import Constants from '../Constants';

const FORM_ENDPOINT = "https://public.herotofu.com/v1/b339e680-f6c1-11ec-bc36-e1ea9ccadd33"; // TODO - fill on the later step

const ContactForm = () => {
    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [status, setStatus] = useState();
    const handleSubmit = async(e) => {
        e.preventDefault();
        //vérifie qu'on a bien le form validé
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }
        
       
        // Anything you need to inject dynamically
        const data = {
            name: name,
            email: email,
            subject: subject,
            message:message,
            templateName:'template_contactstage'
        };

        gtag('event', 'contact', {
          poll_title: 'contact',
        })

        const requestOptions = {
          // mode: 'no-cors',
          method: 'POST',
          headers: { 'Accept': 'application/json, text/plain, */*',
                      'Content-Type': 'application/json', 
          },
          body: JSON.stringify(data)
      };
      //
      let req = await fetch(Constants.API_URL+'/sendemail', requestOptions);
      //vérifie que c'est ok
      if(req.status === 200){
        setStatus(true);
      }else{
        setStatus(false);
      }
      setValidated(true);
        // emailjs.send(emailkey.SERVICE_ID, emailkey.TEMPLATE_CONTACTID, data, emailkey.USER_ID)
        // .then(function(response) {
        //     setStatus("Nous avons bien reçu votre mail et allons revenir vers vous")
        // }, function(error) {
        //     setStatus(error.toString());
        // });
    };

    if(validated){
      if (status) {
        return (
          <>
            <h2>Votre demande a bien été envoyée</h2>
            <div>Nous vous recontacterons le plus rapidement possible.</div>
            <div><NavLink to="/home">Retourner à la page d'accueil</NavLink></div>
          </>
        );
      }
      else{
        return (
          <>
            <h2>Une erreur s'est produite</h2>
            <div>Une erreur s'est produite lors de l'envoie du mail.<br/>Veuillez réessayer plus tard ou envoyer un mail à:<a href="mailto:kandfasbl@outlook.com">kandfasbl@outlook.com</a></div>
            <div><NavLink to="/home">Retourner à la page d'accueil</NavLink></div>
          </>
        );
      }
    }
  
  return (
    <Form
      action={FORM_ENDPOINT}
      onSubmit={handleSubmit}
      method="POST"
      target="_blank"
    >
        <Form.Group className="mb-3" controlId="Name">
            <Form.Label>Nom</Form.Label>
            <Form.Control type="text" placeholder="Jean Dupont" onChange={(e) => setName(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="Email">
            <Form.Label>Email</Form.Label>
            <Form.Control required type="email" placeholder="name@example.com" onChange={(e) => setEmail(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="Sujet">
            <Form.Label>Sujet</Form.Label>
            <Form.Control required type="text" placeholder="Sujet" onChange={(e) => setSubject( e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="Message">
            <Form.Label>Message</Form.Label>
            <Form.Control required as="textarea" rows={3} onChange ={(e) => setMessage(e.target.value)} />
        </Form.Group>
        <Button variant="primary" type="submit"> Send a message </Button>
    </Form>
  );
};

export default ContactForm;