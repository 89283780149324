import { Outlet } from "react-router-dom";
import React, { useEffect } from 'react';

const Traineeship=()=>{
  useEffect(() => {
    document.title = 'kidsandfun stage enfants visé hermalle';
  });
  return(
    <main>
        <Outlet />
      </main>
  )
}

  export default Traineeship;