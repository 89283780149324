import {Button, Form, Modal,Container, Row, Col} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import '../style/subscriptionform.css'
import emailjs from '@emailjs/browser';
import emailkey from "../tools/emailkey";
import { NavLink } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
import { useReducer, stateReducer } from '../utils/stateReducer';
import { v4 as uuidv4 } from 'uuid';
import { insertrow } from "../data/OTraineeship";
import gtag from 'ga-gtag';
import Constants from '../Constants';
const FORM_ENDPOINT = "https://public.herotofu.com/v1/b339e680-f6c1-11ec-bc36-e1ea9ccadd33"; // TODO - fill on the later step

function SubscriptionForm({traineeship}) {
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
  
    const handleClose = () => {setShow(false);setStatus(false);setValidated(false);setErrorMsg("");};
    const handleShow = () => setShow(true);
    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState("");
    const [firstname, setFirstName] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [zip, setZip] = useState("");
    const [city, setCity] = useState("");
    const [birthdate, setBirthdate] = useState("");
    const [contact1, setContact1] = useState({name:"",firstname:"",tel:"",parent:""});
    const [contact2, setContact2] = useState({name:"",firstname:"",tel:"",parent:""});
    const [remark, setRemark] = useState("");
    const [status, setStatus] = useState();
    
    //#region change
    const handleChangeC1 = e => {
      const { name, value } = e.target;
      setContact1(prevState => ({
          ...prevState,
          [name]: value
      }));
    };
    const handleChangeC2 = e => {
      const { name, value } = e.target;
      setContact2(prevState => ({
          ...prevState,
          [name]: value
      }));
    };  
    //#endregion  

    //#region submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        //vérifie qu'on a bien le form validé
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }
        //if(validated){
            // Anything you need to inject dynamically
            const data = {
              sheetName:"stage_"+traineeship.traineeshipID,
              traineeshipID:traineeship.traineeshipID,
              stagename:traineeship.title+" ("+traineeship.description+")",
              email: email,
              firstname: firstname,
              name: name,
              birthdate:birthdate,
              address: address,
              zipcode: zip,
              city:city,
              tel:phone,
              remark:remark,
              contact1:JSON.stringify(contact1),
              contact2:JSON.stringify(contact2),
              contact1name:contact1.name,
              contact1firstname:contact1.firstname,
              contact1parente:contact1.parent,
              contact1tel:contact1.tel,
              contact2name:contact2.name,
              contact2firstname:contact2.firstname,
              contact2parente:contact2.parent,
              contact2tel:contact2.tel,
              subscriptionDate:moment(new Date()).format("DD/MM/YYYY hh:mm:ss"),
              templateName:'template_subscriptionstage'
            };
            //option send to 
            const requestOptions = {
              // mode: 'no-cors',
              method: 'POST',
              headers: { 'Accept': 'application/json, text/plain, */*',
                          'Content-Type': 'application/json', 
              },
              body: JSON.stringify(data)
            };
            //1:log inscription
            // await insertrow(data)
            // .then(async(res)=>{
              //envoie mail to asbl
              // let req = await fetch(Constants.API_URL+'/sendemail', requestOptions);
              // //vérifie que c'est ok
              // if(req.status === 200){
                // 1: envoie mail inscripiton
                emailjs.send(emailkey.SERVICE_ID, emailkey.TEMPLATE_ID, data, emailkey.USER_ID)
                .then( async(response)=>{
                  //2:Envoie mail de confirmation au client
                      emailjs.send(emailkey.SERVICE_ID, emailkey.TEMPLATE_CONFIRM, data, emailkey.USER_ID)
                        .then(async (response)=> {
                          //3:log inscription
                          await insertrow(data)
                          .then(async(res)=>{
                            setValidated(true);
                            setIsLoading(false)
                          }, function(error) {
                            setErrorMsg(error.message);
                            setValidated(true);
                            setIsLoading(false);
                          });
                        },function(error) {
                            setValidated(true);
                            setErrorMsg("Une erreur s'est produite lors de l'envoie de mail au client");
                            setIsLoading(false)
                          });
                }, function(error) {
                  setValidated(true);
                  setStatus(error.toString());
                  setIsLoading(false)
                });
              // }else{
              //   setErrorMsg("Une erreur s'est produite lors de l'envoie de mail à l'asbl");
              // }
              // setValidated(true);
              // setIsLoading(false);
            // }, function(error) {
            //   setErrorMsg(error.message);
            //   setValidated(true);
            //   setIsLoading(false);
            // });

            gtag('event', 'subscription', {
              poll_title: 'inscription',
            })
            //envoie mail à l'asbl
          // emailjs.send(emailkey.SERVICE_ID, emailkey.TEMPLATE_ID, data, emailkey.USER_ID)
          // .then(function(response) {
          //   emailjs.send(emailkey.SERVICE_ID, emailkey.TEMPLATE_CONFIRM, data, emailkey.USER_ID)
          //     .then(function(response) {
          //       setStatus("Nous avons bien reçu votre demande d'inscription et allons revenir vers vous.")
          //       setIsLoading(false)}, function(error) {
          //     setStatus(error.toString());
          //     setIsLoading(false)
          //   });
          // }, function(error) {
          //   setStatus(error.toString());
          //   setIsLoading(false)
          // });
        // }else{
        //   setIsLoading(false);
        // }
    };
    //#endregion  
    //on n'affiche pas les non visible ou date dépassée
    let specific_date = (traineeship!=null)?new Date(moment(traineeship.from,'DD/MM/YYYY').format('YYYY-MM-DD')):new Date();
    let current_date = new Date();
    //si on est en chargement alors affiche loading
    if(isLoading===false){
      if(validated){//le form est bien parti
        if (errorMsg=="") {//cas où l'inscription s'est bien passée
          return (
            <div>
              {traineeship.full.toLowerCase() === "false" && (parseInt(traineeship.RemainPlace)>0 && traineeship.Active.toLowerCase()==="false" && current_date.getTime() < specific_date.getTime()) ? (
                    <Button variant="primary" className="bt-subscription" onClick={handleShow}>Inscrire mon enfant</Button>
                ) : (
                    ""
                )}  
            
      
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Inscription à {traineeship.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="msg-info-header">
                  Votre inscription est bien validée et vous allez recevoir un email de confirmation
                </div>
                <div className="msg-info">Le payement se fera le premier jour du stage</div>
                <div><Button variant="secondary"  onClick={handleClose}>
                      Fermer
                  </Button>
                </div>
              </Modal.Body>
              <Modal.Footer>
              </Modal.Footer>
            </Modal>
          </div>
          );
        }else{//cas où il y a eu une erreur durant l'inscripiton
          return (
            <div>
              {traineeship.full.toLowerCase() === "false" && (traineeship.Active.toLowerCase()==="false" && current_date.getTime() < specific_date.getTime()) ? (
                    <Button variant="primary" className="bt-subscription" onClick={handleShow}>Inscrire mon enfant</Button>
                ) : (
                    ""
                )}  
            
      
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Une erreur s'est produite</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="msg-info-header">
                Une erreur s'est produite lors de l'envoie du mail.<br/>Veuillez réessayer plus tard ou envoyer un mail à:<a href="mailto:kandfasbl@outlook.com">kandfasbl@outlook.com</a>
                </div>
                <div className="msg-info"><NavLink to="/home">Retourner à la page d'accueil</NavLink></div>
                <div className="hide">{errorMsg}</div>
                <div><Button variant="secondary"  onClick={handleClose}>
                      Fermer
                  </Button>
                </div>
              </Modal.Body>
              <Modal.Footer>
              </Modal.Footer>
            </Modal>
          </div>
          );
        }
      }
    }
      //formulaire d'inscription
      return (
        <div>
          {traineeship.full.toLowerCase() === "false" && (traineeship.Active.toLowerCase()==="true" && current_date.getTime() < specific_date.getTime())? (
                  <Button variant="primary" className="bt-subscription" onClick={handleShow}>Inscrire mon enfant</Button>
              ) : (
                  ""
              )}  
    
          <Modal show={show} onHide={handleClose} size="lg">
            {/* div utilisée pour afficher un chargement pendant l'enregistrement */}
            {isLoading ?<div className="loading-panel-overlay"><div className="spinner spinner-modal"></div></div>:""}
            <Modal.Header closeButton>
              <Modal.Title>Inscription à {traineeship.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="msg-info">
                  Une fois votre inscription soumise, nous traiterons votre demande et reviendrons vers vous dans les plus bref délais.
              </div>
              <Form
              action={FORM_ENDPOINT}
              onSubmit={handleSubmit}
              method="POST"
              target="_blank"
              >
                <Row>
                    <Col sm={12} md={6}>
                      <Form.Group className="mb-3" controlId="Email">
                        <Form.Label>Adresse mail</Form.Label>
                        <Form.Control required type="email" placeholder="name@example.com" autoFocus onChange={(e) => setEmail(e.target.value)}/>
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Group className="mb-3" controlId="Phone">
                        <Form.Label>Tél</Form.Label>
                        <Form.Control required type="text" placeholder="0498123456" onChange={(e) => setPhone(e.target.value)}/>
                      </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={4}>
                      <Form.Group className="mb-3" controlId="Prenom">
                        <Form.Label>Prénom de l'enfant</Form.Label>
                        <Form.Control required type="text" placeholder="Jean" onChange={(e) => setFirstName(e.target.value)}/>
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={4}>
                      <Form.Group className="mb-3" controlId="Nom">
                        <Form.Label>Nom de l'enfant</Form.Label>
                        <Form.Control required type="text" placeholder="Dupont" onChange={(e) => setName(e.target.value)}/>
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={4}>
                      <Form.Group className="mb-3" controlId="Birthdate">
                        <Form.Label>Date de naissance</Form.Label>
                        <Form.Control required type="date" onChange={(e) => setBirthdate(e.target.value)}/>
                      </Form.Group>
                    </Col>
                </Row>
                <Form.Group className="mb-3" controlId="Adresse">
                  <Form.Label>Adresse</Form.Label>
                  <Form.Control type="text" placeholder="Rue des souris, 19" onChange={(e) => setAddress(e.target.value)}/>
                </Form.Group>
                <Row className="mb-3">
                <Col xs={4}>
                  <Form.Group  controlId="zipity">
                  <Form.Label>Code postal</Form.Label>
                  <Form.Control type="text" maxLength={4} placeholder="4000" onChange={(e) => setZip(e.target.value)}/>
                  </Form.Group>
                  </Col>
                  <Form.Group as={Col} controlId="city">
                    <Form.Label>Ville</Form.Label>
                    <Form.Control type="text" placeholder="Liège" onChange={(e) => setCity(e.target.value)}/>
                  </Form.Group>
                </Row>
                
                <h3>Contacts</h3>
                <Container>
                  <Row className="header-grid">
                    <Col sm={12} md={3} className="d-none d-md-block"><Form.Label>Nom</Form.Label></Col>
                    <Col sm={12} md={3} className="d-none d-md-block"><Form.Label>Prénom</Form.Label></Col>
                    <Col sm={12} md={3} className="d-none d-md-block"><Form.Label>Tél.</Form.Label></Col>
                    <Col sm={12} md={3} className="d-none d-md-block"><Form.Label>Parenté</Form.Label></Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={3}>
                      <Form.Label className="d-md-none d-sm-block">Nom</Form.Label>
                      <Form.Control required type="text" name="name" placeholder="Dupont" onChange={handleChangeC1}/>
                    </Col>
                    <Col sm={12} md={3}>
                      <Form.Label className="d-md-none d-sm-block">Prénom</Form.Label>
                      <Form.Control required type="text" name="firstname" placeholder="Grégory" onChange={handleChangeC1}/>
                    </Col>
                    <Col sm={12} md={3}>
                      <Form.Label className="d-md-none d-sm-block">Tél</Form.Label>
                      <Form.Control required type="text" name="tel" placeholder="0494147852" onChange={handleChangeC1}/>
                    </Col>
                    <Col sm={12} md={3}>
                      <Form.Label className="d-md-none d-sm-block">Parenté</Form.Label>
                      <Form.Control required type="text" name="parent" placeholder="Père" onChange={handleChangeC1}/>
                    </Col>
                  </Row>
                  <hr className="d-md-none d-sm-block"></hr>
                  <Row>
                    <Col sm={12} md={3}>
                      <Form.Label className="d-md-none d-sm-block">Nom</Form.Label>
                      <Form.Control  type="text" name="name" placeholder="Dupuis" onChange={handleChangeC2}/>
                    </Col>
                    <Col sm={12} md={3}>
                      <Form.Label className="d-md-none d-sm-block">Prénom</Form.Label>
                      <Form.Control  type="text" name="firstname" placeholder="Chantal" onChange={handleChangeC2}/>
                    </Col>
                    <Col sm={12} md={3}>
                      <Form.Label className="d-md-none d-sm-block">Tél</Form.Label>
                      <Form.Control  type="text" name="tel" placeholder="0498987654" onChange={handleChangeC2}/>
                    </Col>
                    <Col sm={12} md={3}>
                      <Form.Label className="d-md-none d-sm-block">Parenté</Form.Label>
                      <Form.Control  type="text" name="parent" placeholder="Mère" onChange={handleChangeC2}/>
                      </Col>
                  </Row>
                </Container>
                <Form.Group className="mb-3" controlId="Remarque" >
                  <Form.Label>Remarque</Form.Label>
                  <Form.Control as="textarea" rows={3}  onChange={(e) => setRemark(e.target.value)}/>
                </Form.Group>
                <Button variant="primary" type="submit" disabled={isLoading}>
                Envoyer
              </Button>
              </Form>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
          </Modal>
        </div>
      );
    
  }
  
  export default SubscriptionForm;