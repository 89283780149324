import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";
import { useAuthenticator, Button,SignIn, Heading, View, Authenticator } from '@aws-amplify/ui-react';
import { NavLink, useNavigate  } from "react-router-dom";

import logo from '../assets/images/LOGO.jpg'
import '../style/navigation.css'

//https://ui.docs.amplify.aws/react/guides/auth-protected
function Navbar(){
    const [isNavExpanded, setIsNavExpanded] = useState(false)
    const navigate = useNavigate();
    function logOut() {
        signOut();
        navigate('/home');
      }
    const { route, signOut } = useAuthenticator((context) => [
        context.route,
        context.signOut,
        context.signIn
      ]);
    return (
            <nav className="navigation">
                <a href="/" className="brand-name">
                    <img src={logo} alt="Kids & Fun" className='nav-logo' />
                    Kids & Fun
                </a>
                <button
                    className="hamburger"
                    onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                    }}
                >
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
                    <ul>
                    <li><NavLink to="/home" onClick={() => setIsNavExpanded(false)}>Accueil</NavLink></li>
                    <li><NavLink to="/traineeship" onClick={() => setIsNavExpanded(false)}>Stages</NavLink></li>
                    <li><NavLink to="/about" onClick={() => setIsNavExpanded(false)}>À propos</NavLink></li>
                    <li><NavLink to="/contact" onClick={() => setIsNavExpanded(false)}>contact</NavLink></li>
                    <li className='li-connection'>{route !== 'authenticated' ? (
                            <NavLink to="/login" onClick={() => setIsNavExpanded(false)}>Login</NavLink>
                        ) : (
                            <NavLink to="" onClick={()=>logOut()}>Logout</NavLink>
                        )}
                        </li>
                    </ul> 
                </div>
            </nav>
    )
}
export default Navbar