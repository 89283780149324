import React from 'react';
import { Outlet } from "react-router-dom";
import Navbar from './Navbar'
import Footer from './Footer'
import '../style/layout.css'
const Layout = ({ children }) => {
    return (
    <React.Fragment>
        <div className='row header-banner'>
            <div className='col-md-2'></div>
            <div className='col-md-8'>
                <Navbar />
            </div>
            <div className='col-md-2'></div>
        </div>
        <div className='row'>
            <div className='col-md-2'></div>
            <div className='col-md-8'>
                <main><Outlet /></main>
            </div>
            <div className='col-md-2'></div>
        </div>
        <div className='row sticky-bottom'>
            <div className='col-md-2'></div>
            <div className='col-md-8'>
                <Footer />
            </div>
            <div className='col-md-2'></div>
        </div>           
    </React.Fragment>
    );
};
export default Layout;