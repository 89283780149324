import { useParams } from "react-router-dom";
import { getTraineeshipById } from "../data/OTraineeship";
import CardDetails from './CardDetails';

function TraineeshipDetails() {
    let params = useParams();
    let traineeship = getTraineeshipById(parseInt(params.traineeshipId, 10));

    return (
      <main>
        
        <CardDetails traineeship={traineeship}/>
      </main>
    );
  }

  export default TraineeshipDetails;