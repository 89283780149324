import '../style/layout.css'
import logo from '../assets/images/LOGO.jpg'
import React, { useEffect } from 'react';
const Programme=()=>{
    useEffect(() => {
        document.title = 'kidsandfun stage enfants visé hermalle';
      });
  return(
    <main style={{ padding: "1rem 0" }}>
        <h2>Programme K&F</h2>
        <p>Outre l'aspect SPORTS, le volet Education prend une part importante de notre
projet.</p>
<p>Durant la construction de la personnalité des jeunes, les vacances sportives doivent
constituer des moments privilégiés pour leur épanouissement.</p>
<p>Les autres pratiques sont tout aussi essentielles : les activités de loisirs et de
détente, les jeux, les repas, la vie en commun, les moments d'échanges, la vie
quotidienne : toutes ces réalités forment un tout indissociable dont nous prendrons
compte !</p>
<p>L’ASBL K&F se compose principalement de moniteurs formés en éducation
physique ou ayant une formation dans les domaines de la pédagogie.</p>
<p>Les enfants seront là pour découvrir divers sports et pour ceux déjà aguerris dans
certaines disciplines, nous perfectionnerons leurs compétences.</p>
<p>Sports collectifs, sports individuels, sports de découvertes, sports de raquettes, ...
Les choix proposés permettront aux enfants de gouter aux différents sports.</p>
<p>Pour les parents, sachez que la <span className='high-important'>SECURITE</span> est le mot clé lors de nos stages.</p>
<div className='row'>
            <div className='col-md-12'>
                <img src={logo} alt={"Kids & Fun"} className='' />
            </div>
        </div>
    </main>
    )}

export default Programme;