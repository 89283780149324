import {Button, Form, Modal,Container, Row, Col} from "react-bootstrap";
import { faEdit, faAdd } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import '../style/subscriptionform.css'
import { NavLink } from "react-router-dom";
import Moment from "moment";
import { useReducer, stateReducer } from '../utils/stateReducer';
import { useCheckPermission } from '../utils/hooks/useCheckPermission';
import { v4 as uuidv4 } from 'uuid';
import { insertTraineeship, updateTraineeship } from "../data/OTraineeship";
import moment from "moment";
import { ConsoleLogger } from "@aws-amplify/core";
import { updaterow, insertrow } from "../data/OTraineeship";

function TraineeshipForm({stageState, stageListState,traineeships, traineeship, editMode}) {
    const [show, setShow] = useState((false));  
    const handleClose = () => {setShow(false);setStatus(false);setValidated(false);setIsLoading(false);setErrorMsg("");};
    const handleShow = () => setShow(true);
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);

    const [errorMsg, setErrorMsg] = useState("");
    const [title, setTitle] = useState((traineeship!=null)?traineeship.title:"");
    const [description, setDescription] = useState((traineeship!=null)?traineeship.description:"");
    const [place, setPlace] = useState((traineeship!=null)?traineeship.place:"");
    const [age, setAge] = useState((traineeship!=null)?traineeship.age:"");
    const [price, setPrice] = useState((traineeship!=null)?traineeship.price:"");
    const [to, setTo] = useState((traineeship!=null)?moment(traineeship.to,"DD/MM/YYYY").format("YYYY-MM-DD"):"");
    const [from, setFrom] = useState((traineeship!=null)?moment(traineeship.from,"DD/MM/YYYY").format("YYYY-MM-DD"):moment(new Date()).format("YYYY-MM-DD"));
    const [horaire, setHoraire] = useState((traineeship!=null)?traineeship.horaire:"");
    const [garderie, setGarderie] = useState((traineeship!=null)?traineeship.garderie:"");
    const [RemainPlace, setRemainPlace] = useState((traineeship!=null)?traineeship.RemainPlace:"10");
    const [AvailablePlace, setAvailablePlace] = useState((traineeship!=null)?traineeship.AvailablePlace:"10");
    const [full, setIsFull] = useState((traineeship!=null)?(traineeship.full==="TRUE")?true:false:false);
    const [active, setIsActive] = useState((traineeship!=null)?(traineeship.Active==="TRUE")?true:false:false);
    const [fulldesc, setFullDesc] = useState((traineeship!=null)?traineeship.fulldesc:"");
    const [file, setFile] = useState(null);
    const [imageUrl, setImageUrl] = useState((traineeship!=null)?traineeship.img:"");
    const [base64file, setBase64File] = useState((traineeship!=null)?traineeship.img:"");
    const [status, setStatus] = useState();

    useEffect(() => {  
      if(file==null || file.length===0)return;
      setImageUrl(URL.createObjectURL(file[0]));
      getBase64(file[0])
        .then(result => {
          file[0]["base64"] = result;
          setBase64File(result);
        })
        .catch(err => {
          console.log(err);
        });
    }, [file]);

    //#region base64
    const getBase64 = file => {
      return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();
  
        // Convert the file to base64 text
        reader.readAsDataURL(file);
  
        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          baseURL = reader.result;
          resolve(baseURL);
        };
      });
    };
    //#endregion
    //#region submit
    const handleSubmitForm = async(e) => {
        setIsLoading(true);
        e.preventDefault();
        //vérifie qu'on a bien le form validé
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }

        let item = {
              rowIndex:(editMode==true)?traineeship.rowIndex:-1,
              traineeshipID:(editMode==false)?uuidv4():traineeship.traineeshipID,
              title:title,
              description:description,
              fulldesc: fulldesc,
              place: place,            
              from:moment(from).format('DD/MM/YYYY'),
              to:moment(to).format('DD/MM/YYYY'),
              price: price,
              horaire:horaire,
              garderie:garderie,
              age:age,
              RemainPlace:RemainPlace,
              AvailablePlace:AvailablePlace,
              full:(full)?"TRUE":"FALSE",
              Active:(active)?"TRUE":"FALSE",
              img:base64file,
              visible:'TRUE',
              sheetName:"stages"
            }
            if(editMode==false){
              insertrow(item).then((res)=>{
                                        if(stageListState!=null){
                                          traineeships.push(item);
                                          stageListState(traineeships);
                                        }
                                        setValidated(res);
                                        setIsLoading(false);
                                      }, function(error) {
                                        setErrorMsg(error.message);
                                        setValidated(true);
                                        setIsLoading(false);
                                      });
            }else{
              updaterow(item).then((res)=>{
                                        setValidated(res);
                                        stageState(item);
                                        setIsLoading(false);
                                      }, function(error) {
                                        setErrorMsg(error.message);
                                        setValidated(true);                                        
                                        setIsLoading(false);
                                      });
            }
    };
    //#endregion  
    if(isLoading===false){
        if (validated) {//la sauvegarde est partie
          if (errorMsg=="") {//la sauvegarde est revenue en succès
            return (
              <div>
                
              <span className="" onClick={handleShow}>
              {editMode===true ? (
                            <FontAwesomeIcon icon={faEdit} />
                        ) : (
                          <FontAwesomeIcon icon={faAdd} />
                        )}          
                </span>
        
              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>test</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="msg-info">
                    {editMode===true ? (<span>La modification a été enregistrée avec succès</span>): (<span>L'insertion s'est déroulée avec succès'</span>)}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary"  onClick={handleClose}>
                        Fermer
                    </Button>
                </Modal.Footer>
              </Modal>
            </div>
            );
          }else{//cas où il y a eu une erreur
            return(
              <Modal show={show} onHide={handleClose}>
                          <Modal.Header closeButton>
                            <Modal.Title>Une erreur s'est produite</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="msg-info-header">
                            Une erreur s'est produite lors de l'enregistrement.<br/>Veuillez réessayer plus tard ou envoyer un mail à votre chef suprême:<a href="mailto:maesfab@gmail.com">maesfab@gmail.com</a>
                            </div>
                            <div className="msg-info">{errorMsg}</div>

                            <div><Button variant="secondary"  onClick={handleClose}>
                                  Fermer
                              </Button>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                          </Modal.Footer>
                        </Modal>
            )
        }
      }
    }
    return (
      <div  style={{ float: "left" }}>
        <span className="link-icon" onClick={handleShow}>
        {editMode===true ? (
                      <FontAwesomeIcon icon={faEdit} />
                  ) : (
                    <FontAwesomeIcon icon={faAdd} />
                  )}
          </span>
  
        <Modal show={show} onHide={handleClose} size="lg">
          <Form onSubmit={handleSubmitForm} method="POST" target="_blank" >
          {/* div utilisée pour afficher un chargement pendant l'enregistrement */}
          {isLoading ?<div className="loading-panel-overlay"><div className="spinner spinner-modal"></div></div>:""}
            <Modal.Header closeButton>
              <Modal.Title>Edit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="msg-info"></div>
              
                <Row>
                    <Col sm={12} md={12}>
                      <Form.Group className="mb-3" controlId="Title">
                        <Form.Label>Titre</Form.Label>
                        <Form.Control required type="Title" placeholder="" autoFocus value={title} onChange={(e) => setTitle(e.target.value)}/>
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={12}>
                      <Form.Group className="mb-3" controlId="desc">
                        <Form.Label>Short description</Form.Label>
                        <Form.Control required type="text" placeholder="" value={description} onChange={(e) => setDescription(e.target.value)}/>
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Group className="mb-3" controlId="Place">
                        <Form.Label>Lieu</Form.Label>
                        <Form.Control required type="text" placeholder="" value={place} onChange={(e) => setPlace(e.target.value)}/>
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Group className="mb-3" controlId="Age">
                        <Form.Label>Age</Form.Label>
                        <Form.Control required type="text" placeholder="" value={age} onChange={(e) => setAge(e.target.value)}/>
                      </Form.Group>
                    </Col>
                </Row>
                <Row> 
                  <Col sm={12} md={6}>
                      <Form.Group className="mb-3" controlId="From">
                        <Form.Label>Du</Form.Label>
                        <Form.Control required type="date" value={from}  onChange={(e) => setFrom(e.target.value)}/> 
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Group className="mb-3" controlId="To">
                        <Form.Label>Au</Form.Label>
                        <Form.Control required type="date" defaultValue={to} placeholder="" onChange={(e) => setTo(e.target.value)}/>
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Group className="mb-3" controlId="Horaire">
                        <Form.Label>Horaire</Form.Label>
                        <Form.Control required type="text" value={horaire} onChange={(e) => setHoraire(e.target.value)}/>
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Group className="mb-3" controlId="Garderie">
                        <Form.Label>Garderie</Form.Label>
                        <Form.Control required type="text" value={garderie} placeholder="" onChange={(e) => setGarderie(e.target.value)}/>
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Group className="mb-3" controlId="Price">
                        <Form.Label>Prix</Form.Label>
                        <Form.Control required type="text" placeholder="" value={price} onChange={(e) => setPrice(e.target.value)}/>
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                    <Form.Group className="mb-3" controlId="AvailablePlace">
                        <Form.Label>Places disponible</Form.Label>
                        <Form.Control required type="text" placeholder="" value={AvailablePlace} onChange={(e) => setAvailablePlace(e.target.value)}/>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="Full">
                        <Form.Check   type="switch"  id="custom-switch" label="Complet?" checked={full} onChange={(e) => setIsFull(e.target.checked)}/>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="Active">
                        <Form.Check   type="switch"  id="custom-switch" label="Actif?" checked={active} onChange={(e) => setIsActive(e.target.checked)}/>
                      </Form.Group>
                    </Col>
                </Row>
                <Form.Group className="mb-3" controlId="fulldesc" >
                  <Form.Label>Description complète</Form.Label>
                  <Form.Control as="textarea" rows={3}  value={fulldesc} onChange={(e) => setFullDesc(e.target.value)}/>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Control type="file" className="custom-file-label" id="inputGroupFile01" label="Photo" onChange={(e) => setFile(e.target.files)}/>
                  <img src={base64file} alr="preview"></img>
                </Form.Group>
              
            </Modal.Body>
            <Modal.Footer>
              <Form.Group as={Row}>
                  <Button variant="primary" type="submit">Enregistrer</Button>
              </Form.Group>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
} 
  export default TraineeshipForm;