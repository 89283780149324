import Banner from '../components/Banner';
import {Helmet} from "react-helmet";
import gtag from 'ga-gtag';
import React, { useEffect } from 'react';
function Home(){
    useEffect(() => {
        document.title = 'kidsandfun stage enfants visé hermalle';
      });
    gtag('event', 'view_home', {
        poll_title: 'vue accueil',
      });
    return(
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="google-site-verification" content="ko-kI5okFHfdGbD19GEEPEKSzttVMWHe0izsNYlH5bg" />
                <title>kidsandfun stage visé-hermalle</title>
            </Helmet>
        <Banner/>
        </div>)
}
export default Home;