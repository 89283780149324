import {Auth}from 'aws-amplify';

export async function useCheckPermission(group){
    return new Promise(async(resolve,error)=>{
        if(Auth == undefined || Auth.user==null)
            resolve(false);
        const user = await Auth.currentAuthenticatedUser();
        let userGroup = user.signInUserSession.accessToken.payload["cognito:groups"];
        // console.log(userGroup);
        resolve(userGroup.includes(group));
        // return (userGroup.includes(group))
        });
}