import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
// import { listTraineeships, getTraineeship } from '../graphql/queries';
// import { createTraineeship as createTraineeshipMutation, updateTraineeship as updateTraineeshipMutation } from '../graphql/mutations';
import config from '../aws-exports';
import moment from 'moment';
API.configure(config);

let KeypointHomeList = [
        {
            id:1,
            title:"Découvrir nos stages",
            link:"/traineeship"
        },
        {
            id:2,
            title:"En route pour Milan",
            link:"/catalog"
        }
    ]
export async function getKeypointHomeList() {
    return KeypointHomeList;
}