
import '../style/App.css';
import { BrowserRouter,Routes, Route  } from "react-router-dom";

import Home from '../routes/Home';
import Traineeship from '../routes/Traineeship';
import TraineeshipDetails from './TraineeshipDetails';
import TraineeshipList from './TraineeshipList';
import Contact from '../routes/Contact';
import Login from '../routes/Login';
import About from '../routes/About';
import Programme from '../routes/Programme';
import Planing from '../routes/Planing';
import Catalog from '../routes/Catalog';
import Layout from './Layout'
import Charte from '../routes/Charte'
import { withAuthenticator,Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { Amplify  } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import config from '../aws-exports';
Amplify .configure(config);

function App() {
  return (
    <Authenticator.Provider>
    <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<Home />} />
          <Route path="home" element={<Home/>} />     
          <Route path="login" element={<Login/>} />        
          <Route path="traineeship" element={<Traineeship/>} >        
              <Route index element={<TraineeshipList/>} />         
              <Route path=":traineeshipId" element={<TraineeshipDetails/>} />
              <Route path="new" element={<TraineeshipDetails/>} />
          </Route>
          <Route path="contact" element={<Contact/>} />
          <Route path="about" element={<About/>} />    
          <Route path="charte" element={<Charte/>} />  
          <Route path="programme" element={<Programme/>} />  
          <Route path="Planing" element={<Planing/>} />  
          <Route path="Catalog" element={<Catalog/>} /> 
          {/* <Navigate  to="/" /> */}
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
      </Authenticator.Provider>
  );
}

export default App;// withAuthenticator(App);
