import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { listTraineeships, getTraineeship } from '../graphql/queries';
import { createTraineeship as createTraineeshipMutation, updateTraineeship as updateTraineeshipMutation } from '../graphql/mutations';
import config from '../aws-exports';
import moment from 'moment';
import useGoogleSheets from 'use-google-sheets';
import Constants from '../Constants';
API.configure(config);

export const APIKEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const STAGE_SHEETID = process.env.REACT_APP_GOOGLE_SHEET_ID;

let traineeshipList = [];
export async function getTraineeships() {
    return new Promise(async(resolve,error)=>{
    // resolve(traineeshipList);

        const requestOptions = {
            // mode: 'no-cors',
            method: 'GET',
            headers: { 'Accept': '*',
                        'Content-Type': 'application/json', 
                        'Origin':Constants.FRONT_URL
            }
        };
        await fetch(Constants.API_URL+'/gettraineeships', requestOptions)
        .then(response => response.json())
        .then(response => {  
            resolve(response);   
        }).catch(err => {console.log(err);return null;});
    });
}
export async function useGetTraineeships() {
    // let filter = {
    //     from: {
    //         gt: moment().format("YYYY-MM-DD")
    //     }
    // };
    // const apiData = await API.graphql({ query: listTraineeships , variables: { filter: filter}});
    // return (apiData.data.listTraineeships.items);
    const { data, loading, error } = await useGoogleSheets({
        apiKey: APIKEY,
        sheetId: STAGE_SHEETID,
        sheetsOptions: [{ id: 'stages' }],
      });
      traineeshipList=(data.length>0)?data[0].data:null;
      console.log(traineeshipList);
    return traineeshipList;
    // // const res = await fetch('https://content-sheets.googleapis.com/v4/spreadsheets/14-Ky0qUPAwf5f_UvoCg5V85G4tyqOTg7jUd7NWPh8SM/values/stages?dateTimeRenderOption=FORMATTED_STRING&majorDimension=ROWS&valueRenderOption=FORMATTED_VALUE&key=279cc6b77f8afa76540ff9431771d7b1f3f9ead4');
    // // const data = await res.json();
    //   return (data.length > 0)?data[0]["data"]:null;
    // return traineeshipList;
}
// export async function getTraineeshipById(id) {
    // const apiData = await API.graphql({ query: getTraineeship, variables: { traineeshipID:  id  } });
    // console.log(apiData.data);
    // return (apiData.data.getTraineeship);
// }
export async function useGetTraineeshipById(id) {
        traineeshipList = await useGetTraineeships();
        debugger;
            console.log(traineeshipList);
            return traineeshipList && traineeshipList.find(
                (t) => t.traineeshipID == id
            );
}
    
export function getTraineeshipById(id) {
    return new Promise(async(resolve,error)=>{
        // resolve(traineeshipList);
        let idx = parseFloat(id)-2;
            const requestOptions = {
                // mode: 'no-cors',
                method: 'GET',
                headers: { 'Accept': '*',
                            'Content-Type': 'application/json', 
                            'Origin':Constants.FRONT_URL
                }
            };
            await fetch(Constants.API_URL+`/gettraineeshipbyindex?traineeshipid=${idx}`, requestOptions)
            .then(response => response.json())
            .then(response => {  
                resolve(response);   
            }).catch(err => {console.log(err);return null;});
        });
    // return traineeshipList.find(
    //     (t) => t.traineeshipID == id
    // );
}

export async function insertTraineeship(item) {
    // return new Promise(async(resolve,reject)=>{console.log("insert Traineeship");
    //     const apiData = await API.graphql(graphqlOperation(createTraineeshipMutation, {input: item}));
    //     console.log(apiData);
    //     resolve(!apiData.hasOwnProperty('errors'));
    // });
}

export async function updaterow(data) {
    return new Promise(async(resolve,error)=>{
        // resolve(traineeshipList);
            const requestOptions = {
                // mode: 'no-cors',
                method: 'POST',
                headers: { 'Accept': '*',
                            'Content-Type': 'application/json', 
                            'Origin':Constants.FRONT_URL
                },
                body:JSON.stringify(data)
            };
            await fetch(Constants.API_URL+`/updaterow`, requestOptions)
            .then((response) => {
                debugger;
                if(response.ok)
                {
                    return response.json();         
                }
                throw new Error('Something went wrong.');                
            })
            .then(response => {  
                resolve(response);   
            }).catch(err => {console.log(err);error(err);});
        });
}
//**************regitration*********** */
export function insertrow(data) {
    return new Promise(async(resolve,error)=>{
        // resolve(traineeshipList);
            const requestOptions = {
                // mode: 'no-cors',
                method: 'POST',
                headers: { 'Accept': '*',
                            'Content-Type': 'application/json', 
                            'Origin':Constants.FRONT_URL
                },
                body:JSON.stringify(data)
            };
            await fetch(Constants.API_URL+`/insertrow`, requestOptions)
            .then((response) => {
                if(response.ok)
                    return response.json();    
                else{
                    return response.json() // return the result of the inner promise, which is an error
                    .then((err) => {
                        error(err);
                    });
                }               
            })
            .then(response => {  
                resolve(response);   
            }).catch(err => {console.log(err);error(err)});
        });
    // return traineeshipList.find(
    //     (t) => t.traineeshipID == id
    // );
}