import Card from './Card';
import { getTraineeships } from "../data/OTraineeship";
import React, { useState, useEffect, useReducer } from 'react';
import { useCheckPermission } from '../utils/hooks/useCheckPermission';
import TraineeshipForm from '../components/TraineeshipForm';
import useGoogleSheets from 'use-google-sheets';
export const APIKEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const STAGE_SHEETID = process.env.REACT_APP_GOOGLE_SHEET_ID;

const TraineeshipList=()=>{
  const [state, setState] = useState(
    // (state, newState) => ({...state, ...newState}),
    {traineeships: [], checkPermission: useCheckPermission("FullAdmin"),isAdmin:false}
  );
  const [loading, setLoading] = useState(false);
  const [traineeships, setTraineeshipList] = useState([]);
  const checkPermission = useCheckPermission("FullAdmin");
  const [isAdmin, setIsAdmin] = useState(false); 
  // debugger;
  // const { data, loading, error } = useGoogleSheets({
  //   apiKey: APIKEY,
  //   sheetId: STAGE_SHEETID,
  //   // sheetsOptions: [{ id: 'stages' }],
  // });
  //setTraineeshipList(data);
  
  useEffect(() => {    
    const fetchData = async () => {
      setLoading(true);
      let res = await checkPermission.then(async(res)=>{
        setIsAdmin(res);
        
        const tempList = await getTraineeships().then((data)=>{
          setTraineeshipList(data);
          setLoading(false);
        });
      });
    }
    fetchData();
  },[setTraineeshipList, setIsAdmin, setLoading]);
  if(loading)
    return (<div className="loader-container">
    <div className="spinner"></div>
  </div>);
  return(
    
        <div className='row'>
          <div className='col-md-12'><h2>{isAdmin===true ? (
                            <TraineeshipForm stageListState={setTraineeshipList} traineeships={traineeships} traineeship={null} editMode={false}/>                        
                    ) : (
                    ""
                    )}Stages</h2></div>
            {traineeships && traineeships.length>0 && traineeships.map((traineeship)=>(
              <Card traineeship={traineeship}/>
            ))}
        </div>
        
  )
}

  export default TraineeshipList;