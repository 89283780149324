import { useParams } from "react-router-dom";
import { getTraineeshipById } from "../data/OTraineeship";
import React, { useState, useEffect } from 'react';
import Moment from "react-moment";
import moment from "moment";
import { useCheckPermission } from '../utils/hooks/useCheckPermission';
import SubcriptionForm from './SubscriptionForm';
import '../style/carddetails.css'
import gtag from 'ga-gtag';

function CardDetails(){
    let params = useParams();
    const checkPermission = useCheckPermission("FullAdmin");
    const [loading, setLoading] = useState(false);
    const [traineeship, setTraineeship] = useState();
    const [isAdmin, setIsAdmin] = useState(false); 
    //let traineeship = getTraineeship(parseInt(params.traineeshipId, 1));
    useEffect(() => {    
        async function fetchData(){
            setLoading(true);
            let res = await checkPermission;
            setIsAdmin(res)
          const tempList = await getTraineeshipById(params.traineeshipId).then((data)=>{
            setTraineeship(data);
            setLoading(false);
            });
          gtag('event', 'vue stage', {
            poll_title:  'vue stage',
          })
        }
        fetchData();
      }, [setIsAdmin, setTraineeship, setLoading]);

    

    if(loading)
      return (<div className="loader-container">
      <div className="spinner"></div>
    </div>);
    //on n'affiche pas les non visible ou date dépassée
    let specific_date = (traineeship!=null)?new Date(moment(traineeship.from,'DD/MM/YYYY').format('YYYY-MM-DD')):new Date();
    let current_date = new Date();
    if(traineeship==null || ((traineeship.Active.toLowerCase()==="false" || current_date.getTime() > specific_date.getTime())&& !isAdmin)){
        return <div>Stage introuvable</div>;
    }
    return (
        <div className='row card-details'>
            <div className='row'>
            <div className='col-md-12'>
            <h2>{traineeship.title}</h2>
            </div>
            </div>
            
            
            <div className="col-lg-8 col-md-6 col-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body text-dark rounded shadow">
                                <div className="row">
                                    <div className="col-12">
                                        <h5 className="mt-2">Informations pratiques :</h5>
                                        {/* <p className="mb-1">Date : <span className="text-secondary">Du <Moment format="DD/MM/YYYY">{traineeship.from}</Moment> au <Moment format="DD/MM/YYYY">{traineeship.to}</Moment></span></p> */}
                                        <p className="mb-1">Date : <span className="text-secondary">Du {traineeship.from} au {traineeship.to}</span></p>
                                        <p className="mb-1">Tranche d'âge : <span className="text-secondary">{traineeship.age}</span></p>
                                        <p className="mb-1">Prix : <span className="text-secondary">{traineeship.price}€ </span></p>
                                        <p className="mb-1">Lieu : {traineeship.place.toLowerCase().indexOf("hermalle")>-1?(<a className="text-primary" href="https://www.google.com/maps/place/Hall+Omnisport/@50.7142561,5.6807928,15z/data=!4m2!3m1!1s0x0:0x880fcc59f7891ce5?sa=X&ved=2ahUKEwjeu_Tzq6X6AhXG26QKHX1EDoAQ_BJ6BAg0EAU" target="_blank">{traineeship.place}</a>) : (
                                                <a className="text-primary" href="https://www.google.com/maps/place/Salle+du+Refuge+d'Aaz/@50.7085677,5.6186703,15z/data=!4m5!3m4!1s0x0:0xe0d65f4195c55021!8m2!3d50.7085654!4d5.618715" >{traineeship.place}</a>
                                            )}  </p>
                                            <h5 className="mt-4">Horaire :</h5>
                                                    <p className="mb-1">Horaire : <span className="text-secondary">{traineeship.horaire}</span></p>
                                                    <p className="mb-1">Garderie : <span className="text-secondary">{traineeship.garderie}</span></p>
                                            <h5 className="mt-4">Description :</h5>
                                        <p className="text-muted pre-wrap mb-1">{traineeship.fulldesc}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="sidebar sticky-bar  rounded shadow">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <img src={traineeship.img} alt={traineeship.title} /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mt-2">
                                    <SubcriptionForm traineeship={traineeship}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        
    );
};
export default CardDetails